import React, { Component } from "react";
import { Link } from 'react-router-dom'
import {
  Select,
  Radio,
  Layout,
  Input,
  Button,
  Table,
  Modal,
  Card,
  DatePicker
} from "antd";

import {
  HomeOutlined,
} from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Papa from 'papaparse';

import "./App.css";
import moment from 'moment';
import Chart from 'chart.js';
import firestore from "./firestore";
import { exportTime, accConverter } from "./transc";
import { RevTable } from './table'

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const Option = Select.Option;

var today;
var today2 = new Date(today).getDate();
var dateForBal = new Array();

var myChart

export class Charts extends Component {
  constructor(props) {
    super(props);


    // Set the default state of our application
    this.state = {
      addingTodo: false,
      pendingTodo: "",
      pendingAmount: "",
      pendingDate: moment(),
      pendingAcc: "",
      transferedacc: "",
      todos: [],
      accountList: [],
      datalo: [],
      disableTransfer: true,
      isOverview: false,
      disabledAcc: 1,
      isExpense: true,
      buttonIsLocked: true,
      type: 'a',
      filteredInfo: null,
      sortedInfo: null,
      thismonth: []
    };

    // We want event handlers to share this context
    this.addRow = this.addRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.tchange = this.tchange.bind(this);
    this.tkchange = this.tkchange.bind(this);
    this.updateBal = this.updateBal.bind(this);
    //start the engine
    this.getAccountList();

  }

  getAccountList() {
    firestore.collection("accounts").onSnapshot(snapshot => {
      let accountList = [];
      snapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        const todo = doc.data();
        todo.id = doc.id;
        accountList.push(todo);

      });
      // Anytime the state of our database changes, we update state
      this.setState({ accountList });
      this.pullFromDB();
    })
  }
  componentWillReceiveProps() {
    //hack
    setTimeout(
      function () {
        this.pullFromDB()
      }
        .bind(this),
      0
    );
  }

  pullFromDB() {
    this.state.datalo = []
    today = new Date().toISOString();
    var condition = ">="
    var page = ""

    //if its not the overall view
    if (this.props.match.params.id) {
      this.state.buttonIsLocked = false
      condition = "=="
      page = this.nameToId(this.props.match.params.id)
      this.state.pendingAcc = page
      this.state.transferedacc = page
    }
    // console.log("condition " + condition + " | page " + page + " | name " + this.props.match.params.id)


    if (page != '') {
      this.setState({ isOverview: false });
    } else {
      this.setState({ isOverview: true });
    }
    // We listen for live changes to our todos collection in Firebase
    firestore.collection('sc').onSnapshot(snapshot => {
      let todos = [];
      snapshot.forEach(doc => {
        const todo = doc.data();

        todo.id = doc.id;

        if (todo.amount.startsWith("MYR")) {
          todo.currency = todo.amount.substring(0, 3)
          todo.amount = todo.amount.substring(3, todo.amount.length)
        }
        todo.date = new Date(todo.date)
        todo.dateObj = this.importTime(todo.date)

        if(todo.dateObj.includes('01/2021')){
          this.state.thismonth.push(todo)
        }
        todos.push(todo);
      });

      // Sort our todos based on time added
      todos.sort(function (a, b) {
        return (
          b.date - a.date
        );
      });

      for (var i = 0; i < todos.length; i++) {
        todos[i].key = i;
      }

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      var items = []
      this.state.thismonth.forEach(e => {
        items.push(e.item)
      });
      var res = items.filter(onlyUnique)
      console.table(res)
      
      //cashflow
      if (todos.length > 0) {
        //reset balance date array
        dateForBal.length = 0;

        //the oldest entry amount is same as its cashflow
        todos[todos.length - 1].cashflow = todos[todos.length - 1].amount

        for (var k = todos.length - 2; k > -1; k--) {
          let tt = parseFloat(todos[k].amount) + parseFloat(todos[k + 1].cashflow)
          todos[k].cashflow = tt.toFixed(2)
          // console.log(todos[k].amount);
        }

        for (var k = todos.length - 1; k > -1; k--) {
          //comparing now and the time.
          if (todos[k].date <= today) {
            // console.log(this.importTime(todos[k].date) + " <= " + this.importTime(today));
            dateForBal.push(todos[k].cashflow);
            // console.log("id " + dateForBal.length);
            // console.log("oldest entry to newest " + dateForBal[dateForBal.length - 1]);
          }
        }
        this.updateBal(page, dateForBal[dateForBal.length - 1])
        for (var i = 0; i < todos.length; i++) {
          this.state.datalo.push(todos[i].cashflow);
        }
        this.updateChart();
      }
      // Anytime the state of our database changes, we update state
      this.setState({ todos });
      console.table(this.state.thismonth)
    })

  }



  uploadMasterUnitsList(file) {
    if (file[0] == null)
      return
    this.uploadWholeUnitList(file[0])
  }
  uploadWholeUnitList(file) {
    let now = Date.now()

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        var a = results.data
        console.log("Finished:", a);

        (async function loop(x) {

          for (const [i, row] of a.entries()) {

            //check if there is value for amount
            if (row.amount.length > 0) {
              let bb = i + 1
              let cc = a.length
              console.log('Importing setting ' + bb + '/' + cc + ' - ' + row.amount)

              let obj = {
                category: row.category,
                date: row.date,
                item: row.item,
                amount: row.amount,
                currency: row.currency,
                timestamp: now,
              }

              // if (row.id == null) {
              //   firestore.collection('sc').add(obj)
              // } else {
              try {
                let doc = await firestore.collection('sc').doc(row.id).get()
                if (doc.exists) {
                  console.log("Document data:", doc.data());
                  // await firestore.collection('sc').doc(row.id).set(obj, { merge: true })

                } else {
                  console.log("No such document!");
                  await firestore.collection('sc').add(obj)
                }
              } catch (error) {
                console.log(error)
              }
            }
          }
        })();

        // }
        console.log("Uploaded successfully")
        // window.location.reload();
      }
    });
  }


  async updateBal(id, amount) {
    if (id != '') {
      // alert("update! record.key: " + id + "todo id: " + this.state.data[id].id)
      // alert("firebasing the pa ta xa...: " + this.state.pa + this.state.ta + this.state.xa + this.state.ya + this.state.sa)
      await firestore
        .collection("accounts")
        .doc(id).update({
          bal: amount
        })
        .then(function () {
          console.log("Document successfully updated!");

        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }
  updateChart() {
    if (myChart != null)
      myChart.destroy()
    var arraa = this.state.datalo
    arraa.reverse()
    myChart = new Chart(document.getElementById("myChart"),
      {
        "type": "line", "data": {
          // "labels": ["January", "February", "March", "April", "May", "June", "July"],
          "labels": arraa,
          "datasets": [
            {
              // "label": this.props.match.params.id,
              // "data": [65, 59, 80, 81, 56, 55, 40],
              "data": arraa,
              "fill": "origin",
              "borderColor": "rgb(75, 192, 192)",
              "lineTension": 0.1
            }
          ]
        },
        options: {
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0, // animation duration after a resize
          responsive: true,
          scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
          legend: {
            display: false,
          },
        }
      });
  }

  deleteRow = async (id) => {
    // this.setState({visible: true})
    console.log(id)
    // await firestore
    //   .collection("sc")
    //   .doc(id)
    //   .delete()
    // this.pullFromDB();

    let snap = await firestore
      .collection("sc")
      .where('timestamp', '==', 1610844066325)
      .get()
    console.log(snap.size);
    snap.forEach(async (doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id);
      await firestore
        .collection("sc")
        .doc(doc.id)
        .delete()
    });
    console.log('Done!');
  }

  async addRow() {
    this.state.datalo = []
    // alert(this.state.date)
    if (!this.state.pendingTodo) return;

    // Set a flag to indicate loading
    this.setState({ addingTodo: true });

    var am = 0
    if (this.state.type === "a")
      am = -Math.abs(this.state.pendingAmount)
    else if (this.state.type === "b")
      am = Math.abs(this.state.pendingAmount)
    else
      am = this.state.pendingAmount

    // Add a new todo from the value of the input
    console.log("adding account ", this.state.pendingAcc, "|transfered account ", this.state.transferedacc)
    var bb = await firestore.collection("todos").add({
      content: this.state.pendingTodo,
      amount: am,
      completed: false,
      date: exportTime(this.state.pendingDate),
      account: this.state.pendingAcc,
      fromacc: this.state.transferedacc
    });

    if (!this.state.disableTransfer) {
      console.log("adding additional entry due to transfer! account ", this.state.pendingAcc, "|transfered account ", this.state.transferedacc)
      var ng = this.state.pendingAmount * -1
      await firestore.collection("todos").add({
        content: this.state.pendingTodo,
        amount: ng,
        date: exportTime(this.state.pendingDate),
        account: this.state.transferedacc,
        fromacc: this.state.pendingAcc
      });
    }

    this.pullFromDB();
    // Remove the loading flag and clear the input
    this.setState({ addingTodo: false, pendingTodo: "", pendingAmount: "", pendingDate: moment() });
  }

  handleChange(date) {
    this.setState({
      pendingDate: date
    });
  }

  onAmountChanged = (e) => {
    var am = e.target.value
    if (am < 0) {
      am = am * -1
      this.setState({ pendingAmount: am })
    } else {
      this.setState({ pendingAmount: am })
    }
  }

  getLatestBal(x) {
    console.log(this.state.todos.cashflow);
  }

  tchange(value) {
    console.log(`selected ${(value)}`);
    this.state.transferedacc = value
    console.log("transfered account is now set to", this.state.transferedacc);
  }

  tkchange(value) {
    if (this.state.buttonIsLocked) {
      this.setState({
        buttonIsLocked: false
      });
    }

    console.log("account has set to ", value, "| pendingAcc value is ", this.state.pendingAcc);
    this.state.pendingAcc = value;
    this.state.transferedacc = value;
    console.log("pendingAcc value is now", this.state.pendingAcc);

  }

  //set type
  setType = (event) => {

    this.state.type = event.target.value


    //if it is transfer:
    this.state.transferedacc = this.state.pendingAcc;
    if (event.target.value === "c") {
      //enable transfer
      this.setState({
        disableTransfer: false
      });
    } else
      //disable transfer
      this.setState({
        disableTransfer: true
      });
    console.log("type status is", this.state.type, "| current account is", this.state.transferedacc);
  }


  tkchange(value) {
    if (this.state.buttonIsLocked) {
      this.setState({
        buttonIsLocked: false
      });
    }

    console.log("account has set to ", value, "| pendingAcc value is ", this.state.pendingAcc);
    this.state.pendingAcc = value;
    this.state.transferedacc = value;
    console.log("pendingAcc value is now", this.state.pendingAcc);

  }
  importTime(s) {
    let v = new Date(s);
    return v.toLocaleDateString();
  }

  importTimeDateOnly(s) {
    var v = new Date(s);
    return v.toLocaleDateString();
  }

  idToName(k) {
    var idtn = 'nope'
    this.state.accountList.forEach((obj, i) => {
      if (k.trim() === obj.id.trim())
        idtn = obj.name
    });
    return idtn
  }
  nameToId(n) {
    var ntid = 'no..'
    this.state.accountList.forEach((obj, i) => {
      if (n.trim() === obj.name.trim())
        ntid = obj.id
    });
    return ntid
  }


  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: 'descend',
        columnKey: 'age',
      },
    });
  };






  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};


    const { classes } = this.props;
    var nana = dateForBal.length - 1
    var currentBal;
    if (nana < 0)
      currentBal = 0
    else
      currentBal = dateForBal[nana]
    // console.log("leggfsdyio " + nana);


    return (
      <div>
        <Layout className="App">


          <div className=''>
            Upload master csv
                    <input
              accept="text/csv"
              className=''
              id="contained-button-file"
              type="file"
              onChange={(e) => this.uploadMasterUnitsList(e.target.files)}
            />
          </div>


          <CSVLink data={this.state.todos}
            filename={`sc.csv`}
          >Export to CSV</CSVLink>
          <hr />

          <Card
            title={<div><Link to='/charts'>Account: {this.props.match.params.id}</Link><br></br>Current balance: RM {currentBal}</div>}
            extra={<RadioGroup
              defaultValue="a"
              size="large">
              <RadioButton value="a">All</RadioButton>
              <RadioButton value="b">Year</RadioButton>
              <RadioButton value="c">Month</RadioButton>
            </RadioGroup>
            }
          // style={{ width: 300 }}
          >
            <canvas id="myChart"></canvas>
          </Card>
          <Card
            title={<div><RadioGroup
              defaultValue="a"
              onChange={this.setType}
              size="large">
              <RadioButton value="a">Expense</RadioButton>
              <RadioButton value="b">Income</RadioButton>
              <RadioButton value="c">Transfer</RadioButton>
            </RadioGroup> <Select
              defaultValue="dont use"
              onChange={this.tchange}
              style={{ width: 120, display: this.state.disableTransfer ? 'none' : 'inline-block' }}
              disabled={this.state.disableTransfer}
              size="large">
                {
                  this.state.accountList.map((c) => <Option key={c.id} value={c.id} >{c.name}</Option>)
                }
              </Select>
            </div>}
          // style={{ width: 300 }}
          >
            <div className="inputt">

              {/* which account to add */}
              <Select
                // defaultValue={this.props.match.params.id}
                onChange={this.tkchange}
                style={{ width: 120, display: this.state.isOverview ? 'inline-block' : 'none' }}
                size="large">
                {
                  this.state.accountList.map((c) => <Option key={c.id} value={c.id} >{c.name}</Option>)
                }
              </Select>

              <DatePicker
                size="large"
                ref="add-todo-input"
                className="App-add-todo-input"
                format="DD-MM-YYYY"
                disabled={this.state.addingTodo}
                selected={this.state.pendingDate}
                onChange={this.handleChange}
                value={this.state.pendingDate}
              />
              <Input
                type="number"
                ref="add-todo-input"
                min={0}
                className="App-add-todo-input"
                size="large"
                placeholder="Amount"
                disabled={this.state.addingTodo}
                onInput={this.onAmountChanged}
                value={this.state.pendingAmount}
                onPressEnter={this.addRow}
                required
              />
              <br></br>
              <Input
                ref="add-todo-input"
                className="App-add-todo-input"
                size="large"
                placeholder="Note"
                disabled={this.state.addingTodo}
                onChange={evt => this.setState({ pendingTodo: evt.target.value })}
                value={this.state.pendingTodo}
                required
              />
              <Button
                className="App-add-todo-button"
                size="large"
                type="primary"
                onClick={this.addRow}
                loading={this.state.addingTodo}
                disabled={this.state.buttonIsLocked}
              >
                Add Transcation
                </Button>

            </div>
          </Card>

          <h1>Account list: {JSON.stringify()}</h1>



          <div id='tabletable'>

            <RevTable
              data={this.state.todos}
              columns={[
                {
                  Header: "date",
                  accessor: "dateObj",
                },
                {
                  Header: "amount",
                  accessor: "amount",
                  Cell: ({ cell }) => (
                    <div>{cell.row.original.currency + ' ' + cell.value}</div>
                  )
                },
                {
                  Header: "outstanding",
                  accessor: "cashflow",
                  Cell: ({ cell }) => (
                    <div>{cell.row.original.currency + ' ' + cell.value}</div>
                  )
                },
                {
                  Header: "item",
                  accessor: "item",
                },
                {
                  Header: "category",
                  accessor: "category",
                },
                {
                  Header: "",
                  accessor: "lol",
                  sortable: false,
                  filterable: false,
                  Cell: ({ cell }) => (
                    <div>

                      <Button
                        className="App-add-todo-button"
                        type="primary"
                        onClick={evt => this.deleteRow(cell.row.original.id)}
                      >
                        <HomeOutlined /></Button>
                    </div>
                  )
                },
                {
                  Header: "key",
                  accessor: "key"
                },
                {
                  Header: "timestamp",
                  accessor: "timestamp",
                  Cell: ({ cell }) => (
                    <Link to={cell.row.original.id} className="flex items-center">
                      {new Date(cell.value).toLocaleString()}
                    </Link>
                  )
                }
              ]}
              defaultSorted={[
                {
                  id: "item",
                  desc: true
                }
              ]}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </div>
          <Modal
            title="Modal 1000px width"
            centered
            visible={this.state.visible}
            onOk={() => this.setState({ visible: false })}
            onCancel={() => this.setState({ visible: false })}
          >
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
          </Modal>


          {/* <List
            className="App-todos"
            size="large"
            bordered
            dataSource={this.state.todos} //all the content arrays, *render item* is specific content of array
            renderItem={todo => (
              <List.Item>
                {todo.date} {}
                {todo.content} {'  MYR '}
                {todo.amount}
                <Icon
                  onClick={evt => this.deleteRow(todo.id)}
                  className="App-todo-complete"
                  type="cross"
                />
              </List.Item>
            )}
          /> */}
        </Layout>
      </div>
    )
  }
}
export default Charts;
