import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Select, Table, Input, InputNumber, Popconfirm, Form, Divider, Button } from 'antd';
import moment from 'moment';
import firestore from "./firestore";



const Option = Select.Option;
const Option2 = Select.Option;

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, {
                                        rules: [{
                                            required: true,
                                            message: `Please Input ${title}!`,
                                        }],
                                        initialValue: record[dataIndex],
                                    })(this.getInput())}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

export class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: '',
            ka: null,
            na: null,
            ga: null,
        }

        this.columns = [
            {
                title: 'PK',
                dataIndex: 'key',
                width: '5%',
                editable: false,
            },
            {
                title: 'Date',
                dataIndex: 'date',
                editable: false,
            },
            {
                title: 'Account',
                dataIndex: 'account',
                editable: false,
            },
            {
                title: 'Transfered Account',
                dataIndex: 'taccount',
                editable: false,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                editable: false,
            },
            {
                title: 'Cashflow',
                dataIndex: 'cashflow',
                editable: false,
            },
            {
                title: 'Note',
                dataIndex: 'note',
                editable: false,
            },
            {
                title: '',
                dataIndex: 'operation',
                width: '15%',
                render: (text, record) => {
                    const editable = this.isEditing(record);

                    return (
                        <div>
                            {editable ? (
                                <span>
                                    <EditableContext.Consumer>
                                        {form => (
                                            <a
                                                href="javascript:;"
                                                onClick={() => this.save(form, record.key)}
                                                style={{ marginRight: 8 }}
                                            >
                                                Save
                            </a>
                                        )}
                                    </EditableContext.Consumer>
                                    <a
                                        href="javascript:;"
                                        style={{ marginRight: 8 }}
                                        onClick={() => this.cancel(record.key)}
                                    >
                                        Cancel
                        </a>
                                </span>
                            ) : (
                                    <div>
                                        <Button
                                            onClick={() => this.edit(record.key)}
                                            className="App-t"
                                        >
                                            Edit
                      </Button>
                                        <Divider type="vertical" />
                                        <Popconfirm
                                            title="Confirm Delete?"
                                            onConfirm={evt => this.deleteAcc(record.key)}
                                        >
                                            <Button>Delete</Button>
                                        </Popconfirm>

                                    </div>
                                )}
                        </div>
                    );
                },
            },
        ];

        this.addAccount = this.addAccount.bind(this);
        this.deleteAcc = this.deleteAcc.bind(this);
        this.updateAcc = this.updateAcc.bind(this);
        this.pullFromDB();
    }

    sect(key, value) {
        this.state.data[key].isGoal = value;
    }



    isEditing = (record) => {
        return record.key === this.state.editingKey;
    };

    edit(key) {
        this.setState({ editingKey: key });
    }

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.state.ka = newData[index].key,
                    this.state.na = newData[index].name,
                    this.state.ga = newData[index].isGoal,

                // alert("the pa ta xa...: " + this.state.pa + this.state.ta + this.state.xa + this.state.ya + this.state.sa)

                this.updateAcc(index)
                this.setState({ data: newData, editingKey: '' });
            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    cancel = () => {
        this.setState({ editingKey: '' });
    };


    importTime(s) {
        var v = new Date(s);
        return v.toLocaleString();
      }
      importTimeDateOnly(s) {
        var v = new Date(s);
        return v.toLocaleDateString();
      }

      idToAccName(id) {
        for (var i = 0; i < this.state.data.length; i++) {
            if(id === this.state.data[i].id)
                alert(this.state.data[i].name) 
        }
      }
      componentDidMount(){
          this.idToAccName("2s6j8QT7kqXe9vMIjCit")
      }


    pullFromDB() {
        var baba;
        if (this.props.match.params.id) {
          baba = this.props.match.params.id
        }
        // We listen for live changes to our data collection in Firebase
        firestore.collection("todos").where("account", "==", baba).onSnapshot(snapshot => {
            //data is locally available array, pulled from firestore.
            let data = [];
            snapshot.forEach(doc => {
                const todo = doc.data();
                todo.id = doc.id;
                data.push(todo);
            });

            // Sort our data based on time added
            data.sort(function (a, b) {
                return (
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                );
            });

            //assign primary key
            for (var i = 0; i < data.length; i++) {
                data[i].key = i;
            }



            // Anytime the state of our database changes, we update state
            this.setState({ data });

        })
    }

    async updateAcc(id) {
        // alert("update! record.key: " + id + "todo id: " + this.state.data[id].id)
        // alert("firebasing the pa ta xa...: " + this.state.pa + this.state.ta + this.state.xa + this.state.ya + this.state.sa)
        await firestore
            .collection("todos")
            .doc(this.state.data[id].id).update({
                key: this.state.ka,
                name: this.state.na,
                isGoal: this.state.ga
            })
            .then(function () {
                console.log("Document successfully updated!");

            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    }

    async deleteAcc(id) {
        // alert("record.key: " + id + "todo id: " + this.state.data[id].id)
        await firestore
            .collection("todos")
            .doc(this.state.data[id].id)
            .delete();
    }

    async addAccount() {
        await firestore.collection("todos").add({
            key: 1,
            name: "test",
            isGoal: "false",
        });

        // this.pullFromDB();

    }
    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'age' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <div className="App">
                <h1>{this.props.match.params.name}</h1>
                <Button
                    className="App-add-todo-button"
                    size="large"
                    type="primary"
                    onClick={this.addAccount}
                >
                    add account!</Button>
                <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                />
            </div>
        )
    }

}


export default Account
