import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Select, Table, Input, InputNumber, Popconfirm, Form, Divider, Button } from 'antd';
import moment from 'moment';
import firestore from "./firestore";



const Option = Select.Option;
const Option2 = Select.Option;

const FormItem = Form.Item;
const EditableContext = React.createContext();




export class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: '',
            ka: null,
            na: null,
            ga: null,
        }

      
    }


    render() {
        return (
            <div className="App">
                <h2>Settings!</h2>
            </div>
        )
    }

}


export default Settings
