import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Breadcrumb, Icon, Divider } from 'antd';
import firestore from "./firestore";

const { Header, Content, Footer, Sider } = Layout;

const SubMenu = Menu.SubMenu;

// The Header creates links that can be used to navigate between routes.
class Headera extends React.Component {
    constructor(props) {
        super(props);
        // Set the default state of our application
        this.state = {
            accountList: []
        };
        this.getAccountList();

    }
    getAccountList() {
        firestore.collection("accounts").onSnapshot(snapshot => {
            let accountList = [];
            snapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const todo = doc.data();
                todo.id = doc.id;
                accountList.push(todo);

            });
            // Anytime the state of our database changes, we update state
            this.setState({ accountList });
        })
    }
    render() {
        return (
            <div>
                {/* <div className="logo" /> */}
                
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    style={{ lineHeight: '64px' }}
                >
                    <Menu.Item>
                        <Link to='/'>Home</Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to='/charts'>Overview</Link>
                    </Menu.Item>
                    {/* <Menu.Item>
                        <Link to='/accounts'>Accounts</Link>
                    </Menu.Item> */}
                    {/* <Menu.Item>
                        <Link to='/Settings'>Settings</Link>
                    </Menu.Item> */}
                    <Divider type="vertical" />
                    {
                        this.state.accountList.map((c) => <Menu.Item><Link to={'/charts/' + c.name}>{c.name}</Link></Menu.Item>)
                    }
                </Menu>
            </div>
        );
    }
}


export default Headera
