import React from 'react'
import Headera from './Header'
import Main from './Main'
import "./App.css";
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;
const App = () => (
  <Layout>
    <Header style={{ width: '100%' }}>
    <Headera />
    </Header>
    <Content style={{ padding: '0 50px', marginTop: 10 }}>
      <Main />
    </Content>
  </Layout>
)

export default App
