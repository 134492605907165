import firebase from "@firebase/app";
import "@firebase/firestore";

console.log(process.env.REACT_APP_BABY)
var config = JSON.parse(process.env.REACT_APP_BABY);

const app = firebase.initializeApp(config);
const firestore = firebase.firestore(app);

const settings = {/* your settings... */ timestampsInSnapshots: true };
firestore.settings(settings);

export default firestore;
