import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import App from './components/App'; 

const theme = createMuiTheme({
  root: {
    flexGrow: 1,
  },
  palette: {
    type: 'dark',
  },
  paper: {
    padding: 2,
    textAlign: 'center',
  },
});

ReactDOM.render(  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <MuiThemeProvider theme={theme}>
    <App />
    </MuiThemeProvider>
  </BrowserRouter>, document.getElementById('root'));
registerServiceWorker();







