import React from 'react'
import { Switch, Route } from 'react-router-dom'

//change it here too
import Charts from './charts'
import Settings from './settings'
import Accounts from './accounts'
import Account from './account'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Accounts}/>
      <Route exact path='/charts/' component={Charts}/>
      <Route path='/charts/:id' component={Charts}/>
      {/* <Route path='/settings' component={Settings}/> */}
      {/* <Route exact path='/accounts' component={Accounts}/> */}
      <Route exact path='/account/:id/:name' component={Account}/>
    </Switch>
  </main>
)

export default Main