export function oldtimeConverter(timestamp) {
    var a = timestamp;
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
}

export function exportTime(x) {
    // var a = x.format("DD-MM-YYYY");
    var a = x.toISOString();
    return a;
}

export function accConverter(x) {
    var a;
    switch (x) {
        case "0":
        a = "unclassified"
        break;
        case "1":
            a = "Main"
            break;
        case "2":
            a = "food"
            break;
        case "3":
            a = "mobility"
            break;
        case "4":
            a = "bills"
            break;
        case "5":
            a = "provision"
            break;
        default:
            a = "ALL"
    }
    // var a = x.format("DD-MM-YYYY");

    return a;
}

export function isoToDate(s) {
    var b = s.split(/\D/);
    return b[2] + '/' + b[1] + '/' + b[0];
}


export function getLatesatBal(x) {

    return x
}
